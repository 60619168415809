import React from "react"

import Layout from "../components/Layout";
import Seo from "../components/Seo";

const NotFoundPage = () => (
    <Layout>
        <Seo title="404" />
        <h1>Greška 404: Nije moguće pronaći stranicu</h1>
        <p>Molimo vas da osvežite stranicu ili da odete na neku drugu.</p>
    </Layout>
)

export default NotFoundPage
